  const experiences = [
    {
      title: "Software Engineer",
      company_name: "American Water",
      date: "January 2025 - Present",
      details: [
        "Joined the <span style='font-weight: bold;'> Enterprise GIS </span> team.",
      ],
      skills: "Skills - ArcPy| Esri | ArcGIS Pro | ArcGIS Online",
      image: "./assets/experience/american-water-company-logo.svg",
    },
    {
      title: "iOS Developer",
      company_name: "Castro Podcasts",
      date: "October 2024 - January 2025",
      details: [
        "Worked in a small team of developers who build features across the <span style='font-weight: bold;'> full stack </span> of the most powerful podcast application on iOS devices.",
        "As part of the Castro team, I improved my skills in implementing the <span style='font-weight: bold;'> MVC (model-view-controller) design pattern</span> and also learned how to <span style='font-weight: bold;'> programmatically crawl the file system of an application. </span>",
        "I implemented the storage page, a feature which provides users with a visualization for how much disk space Castro occupies on their device. The storage page supports <span style='font-weight: bold;'>VoiceOver and detailed storage breakdowns</span>. ",
      ],
      skills: "Skills - Swift | SwiftUI | UIKit | Git | XCode",
      image: "./assets/experience/castro-icon_optimized.png",
    },
    {
      title: "Data Engineering Co-op",
      company_name: "United Parcel Service",
      date: "June 2023 - October 2024",
      details: [
        "Working with a team of <span style='font-weight: bold;'>data engineers</span> who deploy pipelines to process company usage of technical services.",
        "Built an API that processes and conveniently stores <span style='font-weight: bold;'>over 10,000</span> rows of data about cluster compute and virtual machine resource utilization.",
        "Developing a pipeline that monitors and manipulates server runoff and memory anomaly data for <span style='font-weight: bold;'>remote servers in the ecosystem</span>.",
        "Achieved recognition in the 2024 UPS Hackathon for being among the <span style='font-weight: bold;'>top 9 out of 55</span> teams and having the <span style='font-weight: bold;'>highest presentation score</span>. I was responsible for integrating generative AI into my team's project."
      ],
      skills: "Skills - SQL Server | BigQuery | Jenkins | OpenShift | Docker | Postman | Google Cloud Platform | Git | Powershell",
      image: "./assets/experience/ups_exp.png",
    },
    {
      title: "Applications Intern",
      company_name: "Proskauer Rose LLP",
      date: "July 2022 - September 2022",
      details: [
        "Worked in the Information Services department under data analytics and integrations teams using <span style='font-weight: bold;'>SQL, Microsoft Power BI, and in-house web servers</span>.",
        "Migrated <span style='font-weight: bold;'>149 active data-driven subscriptions</span> from legacy reporting server to new subscriptions server.",
        "Addressed issues with <span style='font-weight: bold;'>SQL queries</span> to ensure that relevant data gets extracted and visualized.",
      ],
      skills: "Skills - SQL Server | Power BI",
      image: "./assets/experience/proskauer.jpeg",
    },
    {
      title: "Java Programming Teaching Assistant",
      company_name: "Fordham University Computer Science Department",
      date: "January 2022 - May 2022",
      details: [
        "Worked for the Computer and Information Sciences department to assist a class of <span style='font-weight: bold;'>30 students</span> with Java Programming coursework.",
        "Gained valuable experience <span style='font-weight: bold;'>debugging other students' code</span>, communicating with students about technical material, and reinforcing concepts.",
        "<span style='font-weight: bold;'>Managed</span> grading of examinations and programming assignments.",
      ],
      skills: "Skills - Debugging | Collaborative programming",
      image: "./assets/experience/fordhamcs.jpg",
    },
    {
      title: "Computer Science + Applied Math",
      company_name: "Columbia University and Fordham University",
      date: "2019 - 2024",
      details: [
        "I built my technical foundation as an Applied Mathematics major at Fordham University, where I learned <span style='font-weight: bold;'>data structures and algorithms in C++, as well as Numerical Analysis in Python, and Java Programming</span>.",
        "At Columbia University, I built upon my fundamentals through learning about areas like <span style='font-weight: bold;'>Natural Language Processing, Computer Networks, Parallel Functional Programming, Artificial Intelligence, Pixel Processing, User Interface Design, and Web Development</span>.",
        "I was involved in Columbia University's EcoReps club, where I served on the Executive Board as Communications Co-Chair and <span style='font-weight: bold;'>designed and developed their website</span>. See website <a href=\"https://ecoreps.studentgroups.columbia.edu/about-us\">here</a>.",
      ],
      skills: "Skills - Python | Java | C++ | C | R | numpy | sklearn | TensorFlow | Pytorch | pandas | Haskell | keras | CUDA | TCP | HTTP | UDP",
      image: "./assets/experience/uni.png",
    }
  ];
  
  export { experiences};